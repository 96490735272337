import { useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;

function MonthlyMortgagePayment() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'monthly_mortgage_payment'

    const options = [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3100, 3200, 3300, 3400, 3500, 3600, 3700, 3800, 3900, 4000, 4100, 4200, 4300, 4400, 4500, 4600, 4700, 4800, 4900, 5000, 5100, 5200, 5300, 5400, 5500, 5600, 5700, 5800, 5900]
    
    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = () => {
        if (!inputValue) {
            message.error('Please select monthly mortgage payment.')
            return;
        }
        answersUpdate(question, inputValue)
        stepUpdate()
    }

    function handleChange(value) {
        setInputValue(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your Montly Mortgage Payment?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{ paddingBottom: 30 }}>
                <Col span={4}>
                    <Row justify="center">
                        <Col>
                            <Select defaultValue="Select your monthly mortgage payment" style={{ width: 300 }} onChange={handleChange}>
                                <Option value='0'>0 to 200</Option>
                                {options.map(option => (
                                    <Option value={option}>{option} to {option+100}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default MonthlyMortgagePayment;