import { Row, Col, Image, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useStepUpdateContext } from '../../contexts/step_context';
import { useStepContext } from '../../contexts/step_context';

function BackButton() {
    const stepUpdate = useStepUpdateContext();
    const step = useStepContext();
    const question = 'loan_type'

    const handleBackPageClick = (answer) => {
        stepUpdate('back')
    }

    return (
        <>{
            step > 0 && (
                <Button
                    style={{marginBottom: 20, borderRadius: 10 }}
                    onClick={() => handleBackPageClick('back')}
                >
                    <ArrowLeftOutlined />
                </Button>
            )
        }

        </>
    );
}

export default BackButton;