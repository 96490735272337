import { useState } from 'react';
import { Row, Col, Input, Button, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext, useAnswersContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function Name() {
    const all_answers = useAnswersContext();
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    
    const [firstName, setFirstName] = useState(all_answers.first_name ? all_answers.first_name : '');
    const [lastName, setLastName] = useState(all_answers.last_name ? all_answers.last_name : '');
    
    const handleNextPageClick = (answer) => {
        if (!firstName || !lastName) {
            message.error('Please enter your first and last name.')
            return;
        }
        answersUpdate('first_name', firstName)
        answersUpdate('last_name', lastName)
        stepUpdate()
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your name?</Title>
                    <Text>Personal Information is Safe & Secure.</Text>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                            <p>First Name</p>
                            <Input
                                placeholder="Please enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                style={{ width: "300px" }}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                        <p>Last Name</p>
                            <Input
                                placeholder="Please enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                style={{ width: "300px" }}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                    </Col>
                </Row>
        </>
    );
}

export default Name;