import { useState } from 'react';
import { Row, Col, Input, Button, message, Select, Typography } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext, useAnswersContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import './styles.css';

const { Option } = Select;
const { Title, Text } = Typography;
function Address() {
    const all_answers = useAnswersContext();
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();

    const [address, setAddress] = useState(all_answers.address ? all_answers.address : '');
    const [city, setCity] = useState(all_answers.city ? all_answers.city : '');
    const [state, setState] = useState(all_answers.state ? all_answers.state : '');
    const [zip, setZip] = useState(all_answers.zip_code ? all_answers.zip_code : '');
    
    const stateNames = ["Alaska", "Alabama", "Arkansas", "American Samoa", "Arizona", "California", "Colorado", "Connecticut", "District ", "of Columbia", "Delaware", "Florida", "Georgia", "Guam", "Hawaii", "Iowa", "Idaho", "Illinois", "Indiana", "Kansas", "Kentucky", "Louisiana", "Massachusetts", "Maryland", "Maine", "Michigan", "Minnesota", "Missouri", "Mississippi", "Montana", "North Carolina", "North Dakota", "Nebraska", "New Hampshire", "New Jersey", "New Mexico", "Nevada", "New York", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Virginia", "Virgin Islands", "Vermont", "Washington", "Wisconsin", "West Virginia", "Wyoming"]
    
    const handleNextPageClick = () => {
        if (!address || !city || !state || !zip) {
            message.error('Please fill out all fields.');
            return;
        }
        answersUpdate('zip_code', zip)
        answersUpdate('address', address)
        answersUpdate('city', city)
        answersUpdate('state', state)
        stepUpdate()
    }

    function handleChange(value) {
        setState(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your property address?</Title>
                    <Title level={5}>For verification only. We do not mail.</Title>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                        <p>Address</p>
                            <Input
                                placeholder="Please enter your property address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                        <p>Zip Code</p>
                            <Input
                                placeholder="Please enter your zip code"
                                type='number'
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                        <p>City</p>
                            <Input
                                placeholder="Please enter your city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                        <p>State</p>
                            <Select defaultValue="Select your state" onChange={handleChange} className='address-input' showSearch value={state}>
                                {stateNames.map((state, index) => (
                                    <Option key={index} value={state}>{state}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                    </Col>
                </Row>
        </>
    );
}

export default Address;