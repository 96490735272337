import { useState } from 'react';
import { Row, Col, Input, Button, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext, useAnswersContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function Email() {
    const all_answers = useAnswersContext();
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'email'
    const [inputValue, setInputValue] = useState(all_answers.email ? all_answers.email : '');

    const handleNextPageClick = (answer) => {
        if (inputValue.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
            answersUpdate(question, answer)
            stepUpdate()
          }
        else {
            message.error('Please enter a valid email address.')
        }
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>What's your email?</Title>
                    <Text>We will never sell your information and we will never spam you!</Text>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Row justify="center">
                        <Col>
                            <Input
                                placeholder="Johnny@appleseed.com"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                style={{ width: "300px" }}
                            />
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={() => handleNextPageClick(inputValue)}>Next</Button>
                    </Col>
                </Row>
        </>
    );
}

export default Email;