import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import vivintImage from '../../images/vivint.png'
import './styles.css';

function UpsellVivint() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'upsell_vivint'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}} justify="center">
                <Image 
                    src={vivintImage}
                    width={200}
                    height={40}
                    preview={false}
                />

            </Row>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h1><BackButton />Would you like a FREE Quote from Vivint Smart Home Security?</h1>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col xs={20} xl={4} className="card" onClick={() => handleNextPageClick('yes')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={yesImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Yes</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('no')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={noImage}
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>No</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default UpsellVivint;