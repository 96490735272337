import React, { useContext, useState } from 'react';

// create context for consuming and changing values
const StepContext = React.createContext();
const StepUpdateContext = React.createContext();

// used to access context value **
export function useStepContext() {
    return useContext(StepContext);
}

// used to update context value **
export function useStepUpdateContext() {
    return useContext(StepUpdateContext);
}

// wrap components that needs to access this context
export function StepProvider({ children }) {
    const [step, setStep] = useState(0);

    function updateStep(operation) {
        if (operation == 'back') {
            setStep(step-1);
        } else {
            setStep(step+1);
        }
    }

    return (
        <StepContext.Provider value={step}>
            <StepUpdateContext.Provider value={updateStep}>
                {children}
            </StepUpdateContext.Provider>
        </StepContext.Provider>
    )
}
