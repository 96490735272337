import React, { useContext, useState } from 'react';

// create context for consuming and changing values
const AnswersContext = React.createContext();
const AnswersUpdateContext = React.createContext();

// used to access context value **
export function useAnswersContext() {
    return useContext(AnswersContext);
}

// used to update context value **
export function useAnswersUpdateContext() {
    return useContext(AnswersUpdateContext);
}

const updateDatabase = (question, answer, email, operation) => {
    console.log('updating data')
    var myHeaders = new Headers();
    if (operation == 'update') {
        myHeaders.append("email", email);
        myHeaders.append("updated_key", `${question}:${answer}`);
    } else {
        var raw = JSON.stringify(answer);
    }
    
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
    };

    fetch("https://h0pcbenhei.execute-api.us-west-1.amazonaws.com/prod/", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

function paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }

// wrap components that needs to access this context
export function AnswersProvider({ children }) {
    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.entries())
    let parsedUrlParams = Object.fromEntries(urlParams.entries())
    
    const [answers, setAnswers] = useState(parsedUrlParams ? parsedUrlParams : {});
    function updateAnswers(key, answer) {
        let _answers = answers
        _answers[key] = answer
        _answers["tracking_id"] = window.location.pathname.split('/')[1]
        setAnswers(_answers);
        console.log(answers)
        'email' in _answers && key != 'email' ? updateDatabase(key, answer, _answers.email, 'update') : key == 'email' && updateDatabase(key, _answers, null, 'new')

    }

    return (
        <AnswersContext.Provider value={answers}>
            <AnswersUpdateContext.Provider value={updateAnswers}>
                {children}
            </AnswersUpdateContext.Provider>
        </AnswersContext.Provider>
    )
}
