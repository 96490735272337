import { useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;

function MortgageWith() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'mortgage_with'

    const [inputValue, setInputValue] = useState('');

    const handleNextPageClick = () => {
        if (!inputValue) {
            message.error('Please select your mortgage provider.')
            return;
        }
        answersUpdate(question, inputValue)
        stepUpdate()
    }

    function handleChange(value) {
        setInputValue(value)
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>Who is your current mortgage provider?</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{ paddingBottom: 30 }}>
                <Col span={4}>
                    <Row justify="center">
                        <Col>
                            <Select defaultValue="Select your current mortgage provider" style={{ width: 300 }} onChange={handleChange}>
                            <Option value="bank_of_america">Bank Of America</Option>
                                <Option value="citi_bank">CitiBank</Option>
                                <Option value="chase">Chase</Option>
                                <Option value="quicken_loans">QuickenLoans</Option>
                                <Option value="wells_fargo">Wells Fargo</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleNextPageClick}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default MortgageWith;