import { useState } from 'react';
import { Row, Col, Slider, Button } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext, useAnswersContext } from '../../contexts/answers_context';
import BackButton from '../common/back_button';
import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function PropertyValue() {
    const all_answers = useAnswersContext();
    const [inputValue, setInputValue] = useState(all_answers.property_value ? all_answers.property_value : 250000);
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'property_value'
    console.log()
    const handleNextPageClick = (answer) => {
        
        answersUpdate(question, answer)
        stepUpdate()
    }

    const onValueChange = (value) => {
        setInputValue(value);
    }

    return (
        <>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>How much is your home worth?</Title>
                </Col>
            </Row>
            <Row style={{ paddingBottom: 30 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h1>{
                        inputValue < 130000 ? 'Under $125000' :
                        inputValue > 1980000 ? 'Over $2 Million' :
                        `$${inputValue}`}</h1>
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col xs={20} xl={12}>
                    <Slider
                        min={125000}
                        max={2000000}
                        step={20000}
                        onChange={onValueChange}
                        value={typeof inputValue === 'number' ? inputValue : 250000}
                    />
                </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: 30 }}>
                <Col span={12} style={{textAlign: 'center'}}>
                    <Button type="primary" onClick={() => handleNextPageClick(inputValue)}>Next</Button>
                </Col>
            </Row>
        </>
    );
}

export default PropertyValue;