import { Row, Col, Image } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import BackButton from '../common/back_button';
import './styles.css';

function TheEnd() {
    
    const handleNextPageClick = (answer) => {
        if (answer == 'yes') {
            window.location.replace("https://surveyrewards.cam/");
        } else {
            window.location.replace("http://thankyouhomeowner.com/");
        }
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <h1><BackButton />Would you like to take a 7 question Home Depot survey for a FREE GIFT?</h1>
                </Col>
            </Row>
            <Row justify="center" gutter={16}>
                <Col xs={20} xl={4} className="card" onClick={() => handleNextPageClick('yes')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={yesImage}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Yes</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={4} className="card" style={{marginLeft: 20}} onClick={() => handleNextPageClick('no')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src={noImage}
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>No</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default TheEnd;