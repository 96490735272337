import {useState, useEffect, useRef} from 'react';
import {Row, Col, Button, DatePicker, message, Select, Space, Result, Modal} from 'antd';
import {useStepUpdateContext} from '../../contexts/step_context';
import {useAnswersUpdateContext, useAnswersContext} from '../../contexts/answers_context';
import Terms_And_Conditions from '../common/terms_and_conditions';
import BackButton from '../common/back_button';
import ReactPixel from 'react-facebook-pixel';

import lendgo_logo from '../../images/lendgo_logo.png'
import hla_logo from '../../images/hla.png'
import lmb_logo from '../../images/lmb.png'
import mortgage from "../../images/mortgage.png";

import './styles.css';
import { Typography } from 'antd';
import moment from 'moment'

const advancedMatching = {};
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
};
ReactPixel.init('529182698542697', advancedMatching, options);


const year = moment(new Date("01-01-2010"), "MM-DD-YYYY");
const { Title, Text } = Typography;
const {Option} = Select;

function YearOfBirth() {
    const windowRef = useRef();
    const all_answers = useAnswersContext();
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'year_of_birth'

    const [inputValue, setInputValue] = useState('');
    const [date, setDate] = useState(all_answers.year_of_birth ? all_answers.year_of_birth.split('-')[1] : '');
    const [year, setYear] = useState(all_answers.year_of_birth ? all_answers.year_of_birth.split('-')[2] : '');
    const [month, setMonth] = useState(all_answers.year_of_birth ? all_answers.year_of_birth.split('-')[0] : '');

    const handleNextPageClick = () => {
        console.log(date, month, year)
        answersUpdate(question, month.toString() + '-' + date.toString() + '-' + year.toString())
        setTimeout(() => {
            window.location.href = "http://thankyouhomeowner.com/"
        }, 2000)

    }

    useEffect(() => {
        ReactPixel.trackCustom('lead', {});
    }, [])


    function success() {
        if (!date || !year || !month) {
            message.error('Please select your date of birth!')
            return
        }
        Modal.success({
            content: <Result
                status="success"
                title="Thank You"
                subTitle={<>
                <p> We have matched you with 3 lenders. Prepare to be called to discuss rate and loan scenarios. </p>
                <br/>
                <Row>
                    <Col span={24}>
                        <img src={lendgo_logo} height='25' width='75' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <img src={hla_logo} height='45' width='200' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <img src={lmb_logo} height='45' width='140' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                    <img src={mortgage} height='45' width='160' alt='' style={{margin: 7}}/>
                </Col>
                </Row>
                    </>}
            />,
            onOk: handleNextPageClick
        });
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Title>Please select your birthdate</Title>
                    <Text>Last Question. Click Next For Results</Text>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{paddingBottom: 30}}>
                <Col span={4}>
                    <Row justify="center">
                        <Space>
                            <Col>
                                <Select defaultValue="month" style={{width: 100}} onChange={e => setMonth(e)} value={month}>
                                    {
                                        [...Array(12).keys()].map((month, index) => (
                                            <Option value={month + 1}>{month + 1}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>

                            <Col>

                                <Select defaultValue="date" style={{width: 100}} onChange={e => setDate(e)} value={date}>
                                    {
                                        [...Array(31).keys()].map((date, index) => (
                                            <Option value={date + 1}>{date + 1}</Option>
                                        ))

                                    }
                                </Select>


                            </Col>

                            <Col>
                                <Select defaultValue="year" style={{width: 100}} onChange={e => setYear(e)} value={year}>
                                    {
                                        [...Array(100).keys()].map((year, index) => (
                                            <Option value={2022 - year}>{2022 - year}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{paddingBottom: 30}}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Button type="primary" onClick={success}>Next</Button>
                </Col>
                <Col xs={20} xl={8} style={{textAlign: 'center', color: 'gray'}}>
                    <br/>
                    {/* <Terms_And_Conditions ref={windowRef}/>
                    <Button onClick={() => windowRef.current.showWindow()}>
  Show Window
</Button> */}
                    <p>By clicking the button above, you are providing your electronic signature in which you consent, acknowledge and agree to the following: (a) our <a href='https://buy.calihomeowner.com/terms-conditions'>Terms of Service</a> and <a href='https://buy.calihomeowner.com/privacy-policy'>Privacy Policy</a> , including our arbitration provision. (b) You are providing express written consent to share your information with HomeOwnerCo, and it's <a href='https://buy.calihomeowner.com/network'>network</a> or authorized third parties calling on our, or their behalf who may contact you about their products and services. (c) You give consent (consent not required as a condition to purchase a good/service) for them/us to contact you through automated means, for marketing purposes at the phone number(s) and at the email address you have provided (e.g. automatic telephone dialing system, text, artificial or pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, even if your telephone number is currently listed on any state, federal or corporate Do Not Call registry. You may opt-out from SMS or Text messages at any time by replying STOP. Data and Msg rates may apply. As an alternative, you may contact us by email at: <a href='mailto:customercare@homeownerco.com'>customercare@homeownerco.com</a>. You understand that you may revoke this consent at any time. <a href='https://buy.calihomeowner.com/ccpa'>California Residents</a>.</p>
                </Col>
            </Row>
        </>
    );
}

export default YearOfBirth;