import React, { useState, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'
import logo from './logo.svg';
import {Button} from 'antd';
import './App.css';
import CustomLayout from './layout/index.js';

import ServiceType from './components/form_steps/1_service_type.js';
import PropertyUse from './components/form_steps/2_property_use';
import PropertyType from './components/form_steps/3_property_type';
import PropertyValue from './components/form_steps/4_property_value';
import FirstMortageBalance from './components/form_steps/5_first_mortgage_balance';
import CreditRating from './components/form_steps/6_credit_rating';
import Bankruptcy from './components/form_steps/7_bankruptcy';
import Military from './components/form_steps/8_military';
import Address from './components/form_steps/9_address';
import Name from './components/form_steps/10_name';
import Email from './components/form_steps/11_email';
import Phone from './components/form_steps/12_phone';
import FhaLoan from './components/form_steps/13_fha_loan';
import Occupation from './components/form_steps/14_occupation';
import HomePurchaseYear from './components/form_steps/15_home_purchase_year';
import InterestRate from './components/form_steps/16_interest_rate';
import MortgageWith from './components/form_steps/17_mortgage_with';
import MonthlyMortgagePayment from './components/form_steps/18_monthly_mortgage_payment';
import AdditionalCash from './components/form_steps/19_additional_cash';
import CommunicationMethod from './components/form_steps/20_communication_method';
import YearOfBirth from './components/form_steps/21_year_of_birth';
import TheEnd from './components/form_steps/the_end';

import UpsellSolar from './components/upsells/solar';
import UpsellLeafFilter from './components/upsells/leaf_filter';
import UpsellVivint from './components/upsells/vivint';
import UpsellRenewalByAnderson from './components/upsells/renewal_by_anderson';
import UpsellHomeRoofingSurvey from './components/upsells/home_roofing_survey';
import UpsellChoiceHomeWarranty from './components/upsells/choice_home_warranty';
import UpsellHomeOwnersOfAmerica from './components/upsells/home_owners_of_america';
import UpsellInsuranceSpecialists from './components/upsells/insurance_specialists';
import {useStepContext} from './contexts/step_context';
import styles from './styles.module.css'
const slides = [
    <ServiceType/>,
    <PropertyValue/>,
    <FirstMortageBalance/>,
    <InterestRate/>,
    <CreditRating/>,
    <Bankruptcy/>,
    <Military/>,
    <Occupation/>,
    <Address/>,
    <Name/>,
    <Email/>,
    <Phone/>,
    <YearOfBirth/>,
    <UpsellSolar/>,
    <UpsellRenewalByAnderson/>,
    <UpsellHomeRoofingSurvey/>,
    <UpsellChoiceHomeWarranty/>,
    <UpsellVivint/>,
    <TheEnd/>,
]

function App() {
    const step = useStepContext();
    const [index, set] = useState(0)
    const transitions = useTransition(step, {
        key: step,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 250 },
        exitBeforeEnter: true,
    })
    return (
        <div className="flex fill center">
            {transitions((style, i) => (
                <CustomLayout>
                    <animated.div
                        className={styles.bg}
                        style={{
                            ...style,
                        }}
                    >

                        {slides[i]}

                    </animated.div>
                </CustomLayout>
            ))}
        </div>
    )
}
// <Spring from={{opacity: 0, marginTop: -1000}} to={{opacity: 1, marginTop: 0}}>
//     {props => (
//             <div className="App" style={props}>
//                 <CustomLayout>
//                     {step === 0 && (<ServiceType/>)}
//                     {step === 1 && (<PropertyValue/>)}
//                     {step === 2 && (<FirstMortageBalance/>)}
//                     {step === 3 && (<InterestRate/>)}
//                     {step === 4 && (<CreditRating/>)}
//                     {step === 5 && (<Bankruptcy/>)}
//                     {step === 6 && (<Military/>)}
//                     {step === 7 && (<Occupation/>)}
//                     {step === 8 && (<Address/>)}
//                     {step === 9 && (<Name/>)}
//                     {step === 10 && (<Email/>)}
//                     {step === 11 && (<Phone/>)}
//                     {step === 12 && (<YearOfBirth/>)}
//                     {step === 13 && (<UpsellSolar/>)}
//                     {step === 14 && (<UpsellRenewalByAnderson/>)}
//                     {step === 15 && (<UpsellHomeRoofingSurvey/>)}
//                     {step === 16 && (<UpsellChoiceHomeWarranty/>)}
//                     {step === 17 && (<UpsellVivint/>)}
//                     {step === 18 && (<TheEnd/>)}
//
//                     {/* {step === 1 && ( <PropertyUse /> )} */}
//                     {/* {step === 2 && ( <PropertyType /> )} */}
//                     {/* {step === 12 && ( <FhaLoan /> )}
//                     {step === 14 && ( <HomePurchaseYear /> )}
//                     {step === 16 && ( <MortgageWith /> )}
//                     {step === 17 && ( <MonthlyMortgagePayment /> )} ----
//                     {step === 18 && ( <AdditionalCash /> )}
//                     {step === 19 && ( <CommunicationMethod /> )}
//                     {step === 20 && ( <UpsellLeafFilter /> )}
//                     {step === 26 && ( <UpsellHomeOwnersOfAmerica /> )}
//                     {step === 27 && ( <UpsellInsuranceSpecialists /> )} */}
//                 </CustomLayout>
//             </div>
//         )}
//         </ Spring >
//         );
//     }

export default App;
