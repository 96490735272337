import { Row, Col, Image, Button, Space } from 'antd';
import { useStepUpdateContext } from '../../contexts/step_context';
import { useAnswersUpdateContext } from '../../contexts/answers_context';
import { ArrowLeftOutlined } from '@ant-design/icons';

import BackButton from '../common/back_button';

import sell_logo from '../../images/sell.svg'

import './styles.css';
import { Typography } from 'antd';
const { Title, Text } = Typography;
function ServiceType() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'loan_type'
    
    const handleNextPageClick = (answer) => {
        answersUpdate(question, answer)
        stepUpdate()
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title>Mortgage Rates at 2.25% FIXED (2.39% APR)</Title>
                    <Title level={4}>Takes 2 minutes to see top 5 lenders that will likely approve your loan and offer you a super low rate.</Title>
                </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]}>
                <Col xs={20} xl={3} className="card" onClick={() => handleNextPageClick('refinance')}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/refinance.svg"
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Refinance</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={3} className="card" onClick={() => {window.location.href = 'https://cdmtrk.com/?E=l%2bazRYre8FX8QAtCM7zpcg%3d%3d&s1='}}>
                    <Row justify="center">
                        <Col>
                            <Image 
                                src="https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/icons/purchase.svg" 
                                height={95}
                                width={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Purchase</h1>
                        </Col>
                    </Row>
                </Col>
                <Col xs={20} xl={3} className="card" onClick={() => {window.location.href = 'https://allcashoffers.org/'}}>
                    <Row justify="center">
                        <Col>
                            <Image
                                src={sell_logo}
                                width={95}
                                height={95}
                                alt=''
                                preview={false}
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <h1>Sell</h1>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ServiceType;